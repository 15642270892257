import React from 'react';

import Layout from '@components/Layout';
import ContainerDesktop from '@components/ContainerDesktop';
import ContainerMobile from '@components/ContainerMobile';
import Seo from '@components/Seo';

import logoImage from '@images/marca.svg';

import {
  Container,
  Background,
  Logo,
} from '@styles/index/desktop/styles';

import {
  BackgroundMobile,
  LogoMobile,
} from '@styles/index/mobile/styles';

const IndexPage = () => (
    <Layout>
      <Seo 
        title="VPS do Zero ao Avançado | Seja um especialista em infraestrutura web." 
        description="Treinamento passo a passo para você aprender administrar servidores Linux com facilidade utilizando tecnologias de ponta que grandes empresas como Netflix, Ubber e AirBnb hospedam seus serviços." 
        image="/images/limitado/desktop/Intro/video.png"
      />
      <Container>
        <ContainerDesktop>
          <Background>
            <Logo src={logoImage} alt="VPS do Zero ao Avançado" />
          </Background>
        </ContainerDesktop>
        <ContainerMobile>
          <BackgroundMobile>
            <LogoMobile src={logoImage} alt="VPS do Zero ao Avançado" />
          </BackgroundMobile>
        </ContainerMobile>
      </Container>
    </Layout>
);

export default IndexPage;
