import React from 'react';
import PropTypes from 'prop-types';

import {
  Container,
} from './styles';

const ContainerMobile = ({ children }) => (
    <Container>
        {children}
    </Container>
);

ContainerMobile.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContainerMobile;
