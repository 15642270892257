import styled from 'styled-components';

import backgroundImage from '../../../images/limitado/desktop/background.png';

export const BackgroundMobile = styled.div`
  ${tw`w-full h-screen`};
  ${tw`flex justify-center`}
  /* background-image: url(${backgroundImage}); */
  /* background-color: #131217; */
`;

export const LogoMobile = styled.img`
  ${tw`w-screen`}
`;