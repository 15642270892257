import styled from 'styled-components';

import backgroundImage from '../../../images/limitado/desktop/background.png';

export const Container = styled.div`
  
`;

export const Background = styled.div`
  ${tw`bg-purple justify-center w-full h-screen`};
  ${tw`flex justify-center`}
  /* background-image: url(${backgroundImage}); */
  background-color: #131217;
`;

export const Logo = styled.img`
  width: 800px;
  margin-top: -100px;
`;